(function (factory) {
    typeof define === 'function' && define.amd ? define(factory) :
    factory();
}(function () { 'use strict';

    ///////////////////////////////////
    // typing animation

    jQuery(document).ready(function($) {

        console.log('main.js loaded');

        // document click event listener
        $(document).on('click', function(event) {
            if (!$(event.target).closest('#category-filter-cont').length && $('#category-filter-dropdown').hasClass('active') && !$(event.target).closest('#category-filter-dropdown').length) {
                // The click was outside the #category-filter-cont, so toggle the active class
                $('#category-filter-dropdown').toggleClass('active');
                $('#category-filter-cont').animate({
                    height: 'toggle'
                });
            }
        });

        $('#primary-nav-hamburger').on('click', function(){
            $('body').toggleClass('mobile-nav-active');
            $('#mobile-nav-cont').toggleClass('active');
        });

        $('#close-mobile-menu').on('click', function(){
            $('body').toggleClass('mobile-nav-active');
            $('#mobile-nav-cont').toggleClass('active');
        });

        // category dropdown
        $('#category-filter-dropdown').on('click', function(){
            $('#category-filter-dropdown').toggleClass('active');
            $('#category-filter-cont').animate({
                height: 'toggle'
            });
        });

        // category filter ajax
        $('.category-filter-item').on('click', function(e) {
            // console.log($(this).data('id'));
            var categoryId = $(this).data('id'); // Assuming each category item has a data-id attribute
            $('#category-filter-dropdown').toggleClass('active');
            $('#category-filter-dropdown span').html(categoryId);
            $('#category-filter-cont').animate({
                height: 'toggle'
            });

            $($('.col-gptitem').get().reverse()).each(function(index, el) {
                $(this).delay(index * 20).fadeOut(100);
            });

            // let categoryCount = $(this).data('count');
            // let oldCategoryCount = $('.col-gptitem').length;
            // let categoryRemoveCount = oldCategoryCount - categoryCount;

            // for (let i = 0; i < categoryRemoveCount; i++) {
            //     $('.col-gptitem').last().remove();
            // }

            $.ajax({
                url: myAjax.ajaxurl,
                type: 'POST',
                data: {
                    'action': 'filter_gpt_posts',
                    'categoryId': categoryId
                },
                success: function(posts) {
                    $('#row-gptlist').empty(); // Clear existing posts
                    // console.log(posts);
                    if (posts.length > 0) {
                        posts.forEach(function(post, index) {
                            var postHtml = '<div class="col col-12 col-md-6 col-lg-4 col-xl-3 col-gptitem" style="display: none">';
                            postHtml += '<div class="gptitem-cover"></div>';
                            postHtml += '<a href="' + post.gpt_url + '" target="_blank" class="gpt-cont">';
                            postHtml += '<div class="gpt-body">';
                            postHtml += '<h3 class="gpt-title p-lg">' + post.title + '</h3>';
                            postHtml += '<p class="gpt-desc mb-0">' + post.excerpt + '</p>';
                            postHtml += '</div>';
                            postHtml += '<div class="category-scroll-wrap d-flex justify-content-between">';
                            postHtml += '<div class="category-cont">';
                            post.categories.forEach(function(category) {
                                postHtml += '<span class="category-item">'+category.name+'</span>';
                            });
                            postHtml += '</div>';
                            postHtml += '<div class="upvote-cont d-flex flex-column align-items-center justify-content-between bs-1 '+post.upvoteClass+'" id="upvote" data-gptid="'+post.gptid+'">';
                            postHtml += '<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.07275 5.92901L5.95599 1.04577L10.8392 5.92902" stroke="#939393" stroke-linecap="round" stroke-linejoin="round"/></svg>';
                            postHtml += '<p class="upvote-count text-dlgray mb-0">'+post.upvoteCount+'</p>';
                                    
                            postHtml += '</div>';
                            postHtml += '</div>';
                            postHtml += '</a>';
                            postHtml += '</div>';

                            $(postHtml)
                                .appendTo('#row-gptlist')
                                .delay(index * 50) // Delay increases for each post
                                .fadeIn(100); // Fade in duration
                        });
                    } else {
                        $('#row-gptlist').html('No posts found in this category.').fadeIn();
                    }
                }
            });
        });


        // gpt list hover states
        $(document).on('mouseenter', '.gpt-cont', function(){
            let closestCoverCont = $(this).closest('.col-gptitem').find('.gptitem-cover-cont');
            if (!closestCoverCont.hasClass('active')) {
                $(this).addClass('hover-active');
            }
        }
        ).on('mouseleave', '.gpt-cont', function(){
            $(this).removeClass('hover-active');
        });

        // upvote hover states
        $(document).on('mouseenter', '.upvote-cont', function(){
            var self = this; // Capture the context
            setTimeout(function(el) {
                $(self).closest('.gpt-cont').removeClass('hover-active');
            }, 10);
            $(this).addClass('hover-active');
        }
        ).on('mouseleave', '.upvote-cont', function(){
            $(this).closest('.gpt-cont').addClass('hover-active');
            $(this).removeClass('hover-active');
        });

        // search hover states
        $(document).on('mouseenter', '.filter-cont-search', function(){
            $(this).addClass('hover-active');
        }
        ).on('mouseleave', '.filter-cont-search', function(){
            if( !$('#gpt-search-input').is(":focus") ) {
                $(this).removeClass('hover-active');
            }
        });

        $('#gpt-search-input').on('focus', function(){
            $(this).closest('.filter-cont').addClass('hover-active');
        }).on('blur', function(){
            $(this).closest('.filter-cont').removeClass('hover-active');
        });


        // upvote click
        $(document).on('click', '.upvote-cont', function(e){
            e.preventDefault();
            e.stopPropagation();
            // check if user is logged in
            // prompt create account if not logged in
            let userLoggedIn = isUserLoggedIn();
            if (!userLoggedIn) {
                // animte clicked item first
                $(e.target).closest('.col-gptitem').find('.gptitem-cover').addClass('active');
                setTimeout(function() {
                    $(e.target).closest('.col-gptitem').find('.gptitem-cover-cont').addClass('active');
                    
                }, 300);

                // animate all other items
                let gptItem = $('.col-gptitem');
                gptItem.each(function(index, el) {
                    setTimeout(function() {
                        $(el).find('.gptitem-cover').addClass('active');
                        $(el).find('.gpt-cont').removeClass('hover-active');
                        $(el).find('.upvote-cont').removeClass('hover-active');
                    }, 300); // delay of index * 100 milliseconds
                });
                let signupForm = $('#signup-form').html();
                $(e.target).closest('.col-gptitem').find('.gptitem-cover').html(signupForm);
                return;
            }

            

            let upvoteCount = $(this).find('.upvote-count').text();
            let gptId = $(this).data('gptid');
            let upvote = !$(this).hasClass('upvoted');

            if (!$(this).hasClass('upvoted')) {
                upvoteCount++;
            } else {
                upvoteCount--;
            }
            $(this).toggleClass('upvoted');
            $(this).find('.upvote-count').text(upvoteCount);

            

            $.ajax({
                url: myAjax.ajaxurl,
                type: 'POST',
                data: {
                    'action': 'upvote_gpt',
                    'gptid': gptId,
                    'upvote': upvote 
                },
                success: function(response) {
                    // console.log(response);
                }
            });
        });

        // close sign up box
        $(document).on('click', '.close-gptitem-cover', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $('.gptitem-cover').each(function(index, el){
                $(el).removeClass('active');
                $(el).html('');
            });
        });

        function isUserLoggedIn() {
            if ($('body').hasClass('logged-in')) {
                return true;
            } else{
                return false;
            }
        }

        // $(document).on('click', function(e) {
        //     console.log(e.target)        
        // })

        function searchGpts(searchTerm) {
            $.ajax({
                url: myAjax.ajaxurl,
                type: 'POST',
                data: {
                    'action': 'search_gpts',
                    'searchTerm': searchTerm
                },
                success: function(posts) {
                    $('#row-gptlist').empty(); // Clear existing posts
                    if (posts.length > 0) {
                        posts.forEach(function(post, index) {
                            var postHtml = '<div class="col col-12 col-md-6 col-lg-4 col-xl-3 col-gptitem" style="display: none">';
                            postHtml += '<div class="gptitem-cover"></div>';
                            postHtml += '<a href="' + post.gpt_url + '" target="_blank" class="gpt-cont">';
                            postHtml += '<div class="gpt-body">';
                            postHtml += '<h3 class="gpt-title p-lg">' + post.title + '</h3>';
                            postHtml += '<p class="gpt-desc mb-0">' + post.excerpt + '</p>';
                            postHtml += '</div>';
                            postHtml += '<div class="category-scroll-wrap d-flex justify-content-between">';
                            postHtml += '<div class="category-cont">';
                            post.categories.forEach(function(category) {
                                postHtml += '<span class="category-item">'+category.name+'</span>';
                            });
                            postHtml += '</div>';
                            postHtml += '<div class="upvote-cont d-flex flex-column align-items-center justify-content-between bs-1 '+post.upvoteClass+'" id="upvote" data-gptid="'+post.gptid+'">';
                            postHtml += '<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.07275 5.92901L5.95599 1.04577L10.8392 5.92902" stroke="#939393" stroke-linecap="round" stroke-linejoin="round"/></svg>';
                            postHtml += '<p class="upvote-count text-dlgray mb-0">'+post.upvoteCount+'</p>';
                                    
                            postHtml += '</div>';
                            postHtml += '</div>';
                            postHtml += '</a>';
                            postHtml += '</div>';

                            $(postHtml)
                                .appendTo('#row-gptlist')
                                .delay(index * 50) // Delay increases for each post
                                .fadeIn(100); // Fade in duration
                        }
                        );
                    }
                    else {
                        $('#row-gptlist').html('No GPT\'s found in this category.').fadeIn();
                    }
                }
            });
        }

        // $('#gpt-search-input').on('input', function() {
        //     var currentValue = $(this).val();
        //     console.log(currentValue); // This will log the current value of the input to the console every time you type.
        // });

        var debounceTimer;
        $('#gpt-search-input').on('keyup', function() {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(function() {
                searchGpts($('#gpt-search-input').val());
            }, 500); // Delay in milliseconds
        });

    });

}));
